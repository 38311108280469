
#signup_top_container_div {
    margin: 2vh auto 4vh auto;
    text-align: center;
    width: 40%;
}

    #signup_page_title_div {
        margin: 2vh auto 2vh auto;
        font-size: x-large;
    }

    .signup_input_container_div {
        margin: 0 0 1vh 0;
    }

    #signup_top_container_div .signup_msg_div {
        color: var(--warningMsgColor);
        margin: 0.5vh auto 0 auto;
    }

#signup_button_container_div {
    text-align: center;
    margin: 1vh auto 0 auto;
}

    #signup_button_container_div button {
        margin: 0 0 0 0.5vw;
    }

#signup_privacy_policy_div {
    margin: 0 auto 1vh auto;
}

#signup_terms_of_contract_div {
    margin: 0 auto 1vh auto;
}

#signup_terms_of_contract_checkbox_div {
    text-align: center;
    margin: 0 auto 2vh auto;
}

#signupcomplete_top_container_div {
    margin: 2vh auto 0 auto;
    text-align: center;
}

    #signupcomplete_top_container_div #signupcomplete_msg_container_div {
        font-size: large;
        font-weight: bold;
    }

    #signupcomplete_top_container_div #signupcomplete_button_div {
        margin: 10vh auto 0 auto;
    }

        #signupcomplete_top_container_div #signupcomplete_button_div button:nth-child(1) {
            margin: 0 1vw 0 0;
        }

@media only screen and (max-width: 767px) {

    #signup_top_container_div {
        margin: 2vh auto 4vh auto;
        text-align: center;
        width: 90%;
    }

        #signup_top_container_div .signup_msg_div {
            margin: 0.2vh auto 0 auto;
        }

    #signup_button_container_div {
        text-align: center;
        margin: 4vh auto 0 auto;
    }

        #signup_button_container_div button {
            margin: 0 0 0 2vw;
        }

    #signupcomplete_top_container_div {
        margin: 6vh auto 0 auto;
        text-align: center;
    }

        #signupcomplete_top_container_div #signupcomplete_button_div button:nth-child(1) {
            margin: 0 3vw 0 0;
        }
}