
#hubs_me_div {
    margin: 0 0 1vh 0;
    font-size: medium;
}

    #hubs_me_img {
        text-align: center;
    }

    #hubs_me_name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 0 0 1vw;
    }

.hubs_hub {
    margin: 2vh 0 0 0;
    font-size: medium;
}

    .hubs_hub_img {
        text-align: center;
    }

    .hubs_hub_name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 0 0 1vw;
    }

    .unreadnum_badge {
        margin: 0 0 0 1vw;
    }

#hubs_page_index_div {
    font-family: "Noto-Sans-KR";
    font-size: medium;
    text-align: center;
    margin: 4vh 0 0.5vw 0;
}

    #hubs_page_index_div #page_index_div span {
        margin: 0 0 0 0.5vw;
    }

    #hubs_page_index_div #page_index_div #hubs_page_index_cur_page {
        text-decoration: underline;
        font-weight: 700;
    }

#hubs_search_input_div #hubs_search_guide_message_div {
    color: silver;
    margin: 0 auto 0.5vh auto;
}

#hubs_search_result_div {
    margin: 4vh auto 1vh auto;
    font-size: medium;
}

    #hubs_search_result_div .hubs_search_result {
        margin: 2vh 0 0 0;
    }

        #hubs_search_result_div .hubs_search_result .search_result_pic {
            text-align: center;
        }

        #hubs_search_result_div .hubs_search_result .search_result_username {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 0 0 0 1vw;
        }

    #hubs_search_result_div .search_result_not_found {
        font-family: "Noto-Sans-KR";
        font-size: medium;
        margin: 0 auto 0 auto;
        text-align: center;
    }

#hubs_search_result_page_index_div {
    font-family: "Noto-Sans-KR";
    font-size: medium;
    text-align: center;
    margin: 2vh 0 0.5vw 0;
}

    #hubs_search_result_page_index_div span {
        margin: 0 0 0 0.5vw;
    }

    #hubs_search_result_page_index_div #hubs_search_result_page_index_cur_page {
        text-decoration: underline;
        font-weight: 700;
    }

#hubs_button_div {
    text-align: center;
    margin: 2vh auto 0 auto;
}

#hubs_message_div {
    text-align: center;
    color: crimson;
    margin: 2vh auto 0 auto;
}


@media only screen and (max-width: 767px) {

.hubs_hub {
    margin: 3vh 0 0 0;
    font-size: medium;
}

    #hubs_me_name {
        padding: 0 0 0 0;
    }

    .hubs_hub_name {
        /* font-size: small; */
        font-size: 0.9em;
        padding: 0 0 0 0;
    }

    .hubs_hub_button button {
        /* font-size: small; */
        font-size: 0.9em;
    }

#hubs_page_index_div {
    font-family: "Noto-Sans-KR";
    font-size: medium;
    text-align: center;
    margin: 4vh 0 0 0;
}

    #hubs_page_index_div #page_index_div span {
        margin: 0 0 0 2vw;
    }

    #hubs_create_topic_component button:nth-child(2) {
        margin: 0 0 0 2vw;
    }

    #hubs_search_input_div #hubs_search_guide_message_div {
        font-size: small;
        color: silver;
        margin: 0 auto 1vh auto;
    }

#hubs_search_result_div {
    margin: 6vh auto 1vh auto;
}

        #hubs_search_result_div .hubs_search_result .search_result_username {
            font-size: small;
        }

        #hubs_search_result_div .hubs_search_result .search_result_add_button {
            font-size: small;
        }

    #hubs_search_result_div .search_result_not_found {
        /* font-size: small; */
        font-size: medium;
    }

#hubs_search_result_page_index_div span {
    margin: 0 0 0 2vw;
}

}
