.note_info_main_col_div {
    cursor: pointer;
    margin: 0 0 0.5vh 0;
    border: 1px dashed #123783;
    padding: 2px;
    border-radius: 4px;
}

    .note_info_main_col_div .note_header_row {
        /* background-color: #123783; */
        /* color: white; */
        padding: 0 4px 0 4px;
        /* border-radius: 4px; */
    }

        .note_info_main_col_div .note_header_row .note_writer_div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .note_info_main_col_div .note_header_row .note_timecreated_div {
            text-align: right;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

    .note_info_main_col_div .note_title_row {
        background-color: #123783;
        color: white;
        padding: 0 4px 0 4px;
        border-radius: 4px;
        margin: 0.5vh 0 0 0;
    }

        .note_info_main_col_div .note_title_row .note_subject_title_div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    
        .note_info_main_col_div .note_title_row .note_article_title_div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

            .note_info_main_col_div .note_title_row .note_article_title_div a {
                text-decoration: none;
                color: white;
            }

@media only screen and (max-width: 767px) {

.note_info_main_col_div {
    font-size: 0.9em;
}

}