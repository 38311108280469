
:root {
  --bgColor: white;
  --fontColor: black;
  --deepBlue: #123783;
  --lightBlue: #1cc0fb;
  --brightCyan: #28e5fe;
  --navbarBgColor: white;
  --navbarBoxShadow: darkgray;
  --appFooterBgColor: lightgray;
  --warningMsgColor: crimson;
  --mdSyntaxInfoTitleFontColor: #123783;
  --topicComponentDiv_topicTitleAndButtonDivBdColor: lightgray;
  --ant-btn-default-bgColor: white;
  --ant-btn-default-fontColor: rgba(0, 0, 0, .85);
  --ant-btn-default-bdColor: #d9d9d9;
  --ant-btn-dangerous-bgColor: white;
  --ant-btn-dangerous-fontColor: #ff4d4f;
  --ant-btn-dangerous-bdColor: #ff4d4f;
  --ant-divider-color: rgba(0, 0, 0, .06);
  --ant-switch-bgColor: rgba(0, 0, 0, .25);
  --ant-switch-checked-bgColor: #1890ff;
  --ant-card-bordered-color: rgba(0, 0, 0, .85);
  --ant-card-bordered-bgColor: white;
  --ant-card-bordered-bdColor: #f0f0f0;
  --ant-input-color: rgba(0, 0, 0, .85);
  --ant-input-bgColor: #fff;
  --ant-input-bdColor: #d9d9d9;
  --ant-input-hover-bdColor: #40a9ff;
  --ant-input-affix-wrapper-color: rgba(0, 0, 0, .85);
  --ant-input-affix-wrapper-bgColor: #fff;
  --ant-input-affix-wrapper-bdColor: #d9d9d9;
  --ant-input-affix-wrapper-hover-bdColor: #40a9ff;
  --ant-input-group-color: rgba(0, 0, 0, .85);
  --ant-avatar-group-ant-avatar-bdColor: #fff;
  --ant-drawer-content-bgColor: #fff;
  --ant-menu-color: rgba(0, 0, 0, .85);
  --ant-menu-bgColor: #fff;
  --ant-menu-horizontal-bdColor: #f0f0f0;
  --ant-menu-vertical-bdColor: #f0f0f0;
  --ant-menu-submenu-popup-bgColor: #fff;
  --ant-input-group-addon-color: rgba(0, 0, 0, .85);
  --ant-input-group-addon-bdColor: #d9d9d9;
  --ant-input-group-bgColor: #fafafa;
  --ant-input-password-icon-anticon-color: rgba(0, 0, 0, .45);
  --ant-input-show-count-suffix-color: rgba(0, 0, 0, .45);
  --ant-input-textarea-show-count-color: rgba(0, 0, 0, .45);
  --ant-upload-color: rgba(0, 0, 0, .85);
  --ant-upload-select-picture-card-bgColor: #fafafa;
  --ant-upload-select-picture-card-bdColor: #d9d9d9;
  --ant-collapse-color: rgba(0, 0, 0, .85);
  --ant-collapse-bgColor: #fafafa;
  --ant-collapse-bdColor: #d9d9d9;
  --ant-collapse-header-color: rgba(0, 0, 0, .85);
  --ant-collapse-item-bdColor: #d9d9d9;
  --ant-collapse-content-color: rgba(0, 0, 0, .85);
  --ant-collapse-content-bgColor: #fff;
  --ant-collapse-content-bdColor: #d9d9d9;
  --ant-checkbox-wrapper-color: rgba(0, 0, 0, .85);
}

.dark_mode {
  --bgColor: #1e1e1e;
  --fontColor: lightgray;
  --deepBlue: #123783;
  --lightBlue: #1cc0fb;
  --brightCyan: #28e5fe;
  --navbarBgColor: #2e2e2e;
  --navbarBoxShadow: #4e4e4e;
  --appFooterBgColor: #2e2e2e;
  --warningMsgColor: #1cc0fb;
  --mdSyntaxInfoTitleFontColor: #1cc0fb;
  --topicComponentDiv_topicTitleAndButtonDivBdColor: darkgray;
  --ant-btn-default-bgColor: #1e1e1e;
  --ant-btn-default-fontColor: rgba(255, 255, 255, .85);
  --ant-btn-default-bdColor: rgba(255, 255, 255, .85);
  --ant-btn-dangerous-bgColor: #1e1e1e;
  --ant-btn-dangerous-fontColor: #1cc0fb;
  --ant-btn-dangerous-bdColor: #1cc0fb;
  --ant-divider-color: rgba(78, 78, 78, .85);
  --ant-switch-bgColor: rgba(88, 88, 88, .75);
  --ant-card-bordered-color: rgba(169, 169, 169, .85);
  --ant-card-bordered-bgColor: #2e2e2e;
  --ant-card-bordered-bdColor: #4e4e4e;
  --ant-input-color: darkgray;
  --ant-input-bgColor: black;
  --ant-input-bdColor: #4e4e4e;
  --ant-input-hover-bdColor: #d9d9d9;
  --ant-input-affix-wrapper-color: darkgray;
  --ant-input-affix-wrapper-bgColor: black;
  --ant-input-affix-wrapper-bdColor: #4e4e4e;
  --ant-input-affix-wrapper-hover-bdColor: #d9d9d9;
  --ant-input-group-color: darkgray;
  --ant-avatar-group-ant-avatar-bdColor: rgba(0, 0, 0, .9);
  --ant-drawer-content-bgColor: #4e4e4e;
  --ant-menu-color: #f0f0f0;
  --ant-menu-bgColor: #4e4e4e;
  --ant-menu-horizontal-bdColor: darkgray;
  --ant-menu-vertical-bdColor: darkgray;
  --ant-menu-submenu-popup-bgColor: #4e4e4e;
  --ant-input-group-addon-color: #f0f0f0;
  --ant-input-group-addon-bdColor: #4e4e4e;
  --ant-input-group-bgColor: #2e2e2e;
  --ant-input-password-icon-anticon-color: darkgray;
  --ant-input-show-count-suffix-color: #4e4e4e;
  --ant-input-textarea-show-count-color: #4e4e4e;
  --ant-upload-color: #f0f0f0;
  --ant-upload-select-picture-card-bgColor: #3e3e3e;
  --ant-upload-select-picture-card-bdColor: #4e4e4e;
  --ant-collapse-color: #f0f0f0;
  --ant-collapse-bgColor: #2e2e2e;
  --ant-collapse-bdColor: #4e4e4e;
  --ant-collapse-header-color: #f0f0f0;
  --ant-collapse-item-bdColor: #4e4e4e;
  --ant-collapse-content-color: darkgray;
  --ant-collapse-content-bgColor: #2e2e2e;
  --ant-collapse-content-bdColor: #4e4e4e;
  --ant-checkbox-wrapper-color: darkgray;
}

body {
  margin: 0;
  background-color: var(--bgColor);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

