
    #create_note_title_div > span {
        font-size: medium;
    }

#create_note_title_msg_div {
    color: crimson;
    margin: 1vh 0 0 1vw;
}

#create_note_desc_input_div {
    margin: 1vh auto 0 auto;
}

    #create_note_desc_input_div textarea {
        font-size: medium;
        line-height: 180%;
    }

#create_note_desc_auxiliary_div {
    margin: 4vh auto 1vh auto;
}

    /* #create_note_desc_auxiliary_div #create_note_MDOutput_p { */
    #create_note_desc_auxiliary_div #editor_output {
        font-size: medium;
        line-height: 180%;
    }

#create_note_button_div {
    display: flex;
    margin: 2vh auto 1vh auto;
    text-align: center;
    justify-content: center;
    align-items: center;
}

    #create_note_button_div :nth-child(1){
        margin: 0 0 0 0;
    }

    #create_note_button_div :nth-child(2){
        margin: 0 0 0 1vw;
    }

    #create_note_button_div :nth-child(3){
        margin: 0 0 0 1vw;
    }

#create_note_update_msg_div {
    color: crimson;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    #create_note_button_div :nth-child(1){
        margin: 0 0 0 0;
    }

    #create_note_button_div :nth-child(2){
        margin: 0 0 0 4vw;
    }

    #create_note_button_div :nth-child(3){
        margin: 0 0 0 5vw;
    }
}
