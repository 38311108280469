

/* #manage_notice_notice_no_div {

} */

#manage_notice_display_div {
    margin: 1vh auto 0 auto;
}

#manage_notice_title_div {
    margin: 1vh auto 0 auto;
}

#manage_notice_content_div {
    margin: 1vh auto 0 auto;
}

#manage_notice_msg_div {
    color: crimson;
}

#manage_notice_button_div {
    text-align: center;
    margin: 2vh auto 0 auto;
}

        #manage_notice_button_div > div > button:nth-child(2){
            margin: 0 0 0 1vw;
        }

        #manage_notice_button_div > div > button:nth-child(3){
            margin: 0 0 0 1vw;
        }

        #manage_notice_button_div > div > button:nth-child(4){
            margin: 0 0 0 1vw;
        }

@media only screen and (max-width: 767px) {

        #manage_notice_button_div > div > button:nth-child(2){
            margin: 0 0 0 4vw;
        }
        
        #manage_notice_button_div > div > button:nth-child(3){
            margin: 0 0 0 4vw;
        }

        #manage_notice_button_div > div > button:nth-child(4){
            margin: 0 0 0 4vw;
        }

}
