.app_footer_div {
    text-align: center;
    background-color: var(--appFooterBgColor);
}

    /* .app_footer_div img {
        height: 1.5em;
    } */

    .app_footer_div svg {
        height: 1.5em;
        fill: var(--fontColor);
    }

