

    #reqpwreset_notice_div > div:nth-child(2) {
        text-align: center;
        font-size: medium;
    }

#reqpwreset_email_input_div {
    margin: 1vh auto 0 auto;
}

#reqpwreset_msg_div {
    margin: 1vh auto 0 auto;
    text-align: center;
    color: crimson;
}

#reqpwreset_button_div {
    margin: 4vh auto 0 auto;
}

    #reqpwreset_button_div > div:nth-child(2) {
        text-align: center;
    }

        #reqpwreset_button_div > div:nth-child(2) > button:nth-child(2) {
            margin: 0 0 0 1vw;
        }

@media only screen and (max-width: 767px) {

    #reqpwreset_notice_div > div:nth-child(2) {
        text-align: center;
        font-size: small;
    }

#reqpwreset_email_input_div {
    margin: 2vh auto 0 auto;
}

#reqpwreset_button_div {
    margin: 6vh auto 0 auto;
}

    #reqpwreset_button_div > div:nth-child(2) {
        text-align: center;
    }

        #reqpwreset_button_div > div:nth-child(2) > button:nth-child(2) {
            margin: 0 0 0 4vw;
        }
}