/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.App {
  height: 100%;
}

.mobile_visible {
  display: none;
}

.mobile_hidden {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--fontColor);
}

.ant-layout-header {
  background-color: white;
  padding: 0;
}

.ant-layout {
  height: auto;
  min-height: 100%;
  background-color: white;
}

.ant-layout-footer {
  padding: 1vh;
  background: var(--appFooterBgColor);
}

.ant-btn-default {
  color: var(--ant-btn-default-fontColor);
  background: var(--ant-btn-default-bgColor);
  border: 1px solid var(--ant-btn-default-bdColor);
}

.ant-btn-dangerous {
  color: var(--ant-btn-dangerous-fontColor);
  background: var(--ant-btn-dangerous-bgColor);
  border: 1px solid var(--ant-btn-dangerous-bdColor);
}

.ant-divider {
  border-top: 1px solid var(--ant-divider-color);
}

.ant-switch {
  background-color: var(--ant-switch-bgColor);
}

.ant-switch-checked {
  background-color: var(--ant-switch-checked-bgColor);
}

.ant-card-bordered {
  background-color: var(--ant-card-bordered-bgColor);
  border: 1px solid var(--ant-card-bordered-bdColor);
}

.ant-card-bordered .ant-card-head {
  border-bottom: 1px solid var(--ant-card-bordered-bdColor);
  color: var(--ant-card-bordered-color);
}

.ant-input {
  color: var(--ant-input-color);
  background-color: var(--ant-input-bgColor);
  border: 1px solid var(--ant-input-bdColor);
}

.ant-input:hover {
  border-color: var(--ant-input-hover-bdColor);
}

.ant-input-affix-wrapper {
  background-color: var(--ant-input-affix-wrapper-bgColor);
  border: 1px solid var(--ant-input-affix-wrapper-bdColor);
  color: var(--ant-input-affix-wrapper-color);
}

.ant-input-affix-wrapper:hover {
  border-color: var(--ant-input-affix-wrapper-hover-bdColor);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--ant-input-affix-wrapper-hover-bdColor);
}

.ant-input-group {
  color: var(--ant-input-group-color);
}

.ant-avatar-group .ant-avatar {
  border: 1px solid var(--ant-avatar-group-ant-avatar-bdColor);
}

.ant-drawer-content {
  background-color: var(--ant-drawer-content-bgColor);
}

.ant-menu {
  color: var(--ant-menu-color);
  background: var(--ant-menu-bgColor);
}

  .navbar_menu_div .ant-menu {
    color: var(--ant-menu-color);
    background: var(--navbarBgColor);
  }

  .navbar_menu_div .ant-menu-horizontal {
    border-bottom: 1px solid var(--ant-menu-horizontal-bdColor);
  }

.ant-menu-vertical {
  border-right: 1px solid var(--ant-menu-vertical-bdColor);
}

.ant-menu-submenu-popup .ant-menu {
  background-color: var(--ant-menu-submenu-popup-bgColor);
}

.ant-input-group-addon {
  color: var(--ant-input-group-addon-color);
  background-color: var(--ant-input-group-bgColor);
  border: 1px solid var(--ant-input-group-addon-bdColor);
}

.ant-input-password-icon.anticon {
  color: var(--ant-input-password-icon-anticon-color);
}

.ant-input-show-count-suffix {
  color: var(--ant-input-show-count-suffix-color);
}

.ant-input-textarea-show-count::after {
  color: var(--ant-input-textarea-show-count-color);
}

.ant-upload.ant-upload-select-picture-card {
  background-color: var(--ant-upload-select-picture-card-bgColor);
  border: 1px solid var(--ant-upload-select-picture-card-bdColor);
}

.ant-upload {
  color: var(--ant-upload-color);
}

.ant-collapse {
  color: var(--ant-collapse-color);
  background-color: var(--ant-collapse-bgColor);
  border: 1px solid var(--ant-collapse-bdColor);
}
.ant-collapse>.ant-collapse-item {
  border-bottom: 1px solid var(--ant-collapse-item-bdColor);
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  color: var(--ant-collapse-header-color);
}

.ant-collapse-content {
  color: var(--ant-collapse-content-color);
  background-color: var(--ant-collapse-content-bgColor);
  border-top: 1px solid var(--ant-collapse-content-bdColor);
}

.ant-checkbox-wrapper {
  color: var(--ant-checkbox-wrapper-color);
}


/*  md size */
@media only screen and (max-width: 767px) {

  .mobile_visible {
      display: block;
  }

  .mobile_hidden {
      display: none;
  }

  .ant-layout-footer {
    margin: 0;
  }

}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');

