
/* .dark_mode {
    --bgColor: #121212;
    --fontColor: darkgray;
} */

#home_top_container_div {
    /* border: 2px solid crimson; */
    width: fit-content;
    margin: 0 auto 0 auto;
    line-height: 200%;
}

#home_lookaround_hubs_div {
    margin: 5vh auto 0 auto;
    width: 70%;
}

#home_lookaround_hubs_div .ant-card-head-title {
    text-align: center;
}

/* #home_lookaround_hubs_div > div:nth-child(1) {
    text-align: center;
    font-size: large;
    margin: 1vh auto 3vh auto;
} */

#home_notice_button_div {
    text-align: center;
}

@media only screen and (max-width: 767px) {

#home_top_container_div {
    /* border: 2px solid crimson; */
    width: fit-content;
    margin: 0 auto 0 auto;
    font-size: small;
}

#home_lookaround_hubs_div {
    width: 100%;
}

    /* #home_lookaround_hubs_div .ant-card-head-title {
        font-size: small;
    } */

}
