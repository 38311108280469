
#note_title_div {
    font-weight: bold;
    font-size: x-large;
}

    #note_desc_div #note_MDOutput_p {
        line-height: 180%;
        font-size: medium;
    }

#note_copyright_div {
    text-align: right;
}

#note_lastupdate_div {
    text-align: right;
}

#note_print_share_button_div {
    margin: 2vh auto 0 auto;
    text-align: center;
}

#note_print_share_button_div button:nth-child(2) {
    margin: 0 0 0 1vw;
}

#note_confirm_deletion_div {
    text-align: center;
}

    #note_confirm_deletion_div :nth-child(1) {
        margin: 0 0 1vh 0;
    }

    #note_confirm_deletion_div :nth-child(3) {
        margin: 0 0 0 1vw;
    }

#note_button_div {
    text-align: center;
}

    #note_button_div button:nth-child(2) {
        margin: 0 0 0 1vw;
    }

    #note_button_div button:nth-child(3) {
        margin: 0 0 0 1vw;
    }

#hub_info_list_div {
    margin: 4vh auto 1vh auto;
}

    #hub_info_list_div .share_friend_info_div {
        margin: 2vh 0 0 0;
    }

    #hub_info_list_div .no_friend_msg_div {
        font-family: "Noto-Sans-KR";
        font-size: medium;
        text-align: center;
        margin: 1vh 0 0 0;
    }

#page_index_div {
    font-family: "Noto-Sans-KR";
    font-size: medium;
    text-align: center;
    margin: 4vh 0 0.5vw 0;
}

    #page_index_div span {
        margin: 0 0 0 0.5vw;
    }

    #page_index_div #share_friend_page_index_cur_page {
        text-decoration: underline;
        font-weight: 700;
    }


@media only screen and (max-width: 767px) {

    .share_friend_name_div {
        font-size: small;
    }

    .share_friend_button_div button {
        font-size: small;
    }

    #note_print_share_button_div Button:nth-child(2){
        margin: 0 0 0 4vw;
    }

    #note_button_div button:nth-child(2) {
        margin: 0 0 0 4vw;
    }

    #note_button_div button:nth-child(3) {
        margin: 0 0 0 4vw;
    }

    #note_confirm_deletion_div :nth-child(3) {
        margin: 0 0 0 4vw;
    }
}

@media print {

    .dark_mode {
        --bgColor: white;
        --fontColor: black;
    }

    body {
        visibility: hidden;
    }

    .ant-layout-header {
        display: none;
    }

    .app_layout {
        margin: 0;
    }

    #note_note_div {
        visibility: visible;
        position: absolute;
        width: 100%;
        margin: 0;
        padding: 0;
        break-before: always;
        break-after: always;
    }

    /* #note_note_div {
        visibility: visible;
        position: relative;
        display: table;
        table-layout: fixed;
        padding-top: 200px;
        padding-bottom: 200px;
        width: 94%;
        height: auto;
        page-break-before: always;
        page-break-after: always;
    } */

    #note_desc_div #note_MDOutput_p {
        line-height: 200%;
        font-size: medium;
    }

    #note_copyright_div {
        text-align: right;
    }

    #note_lastupdate_div {
        text-align: right;
    }
}

@page {
    margin-top: 25mm;
    margin-bottom: 25mm;
}
