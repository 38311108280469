
#agora_title_div {
    text-align: center;
    font-size: medium;
    margin: 0 auto 0 auto;
}

#agora_input_div {
    margin: 2vh auto 0 auto;
}

#agora_input_msg_div {
    color: crimson;
}

#agora_button_div {
    text-align: center;
    margin: 2vh auto 0 auto;
}

#agora_contents_div {
    margin: 2vh auto 0 auto;
}

        /*  each suggestion item   */
        #agora_contents_div #agora_suggestion_items_div > div {
            margin: 2vh auto 0 auto;
            border-bottom: 1px dashed silver;
        }

        /* #agora_contents_div > div:nth-child(2) {
            border: 1px solid crimson;
        }

        #agora_contents_div #agora_suggestion_items_div {
            border: 1px solid blue;
        } */

        /* #suggestion_contents_div > div:nth-child(2) .suggestion_item_time_suggested_div {
            justify-content: center;
        } */

        #agora_contents_div #agora_suggestion_items_div .suggestion_item_title_div {
            text-align: left;
        }

        #agora_contents_div #agora_suggestion_items_div .suggestion_suggestions .suggestion_item_title_div {
            /* border-left: 10px solid #1cc0fb; */
            background-color: #1cc0fb;
            border-radius: 5px;
            padding-left: 5px;
            color: black;
        }

        #agora_contents_div #agora_suggestion_items_div .suggestion_answers .suggestion_item_title_div {
            /* border-left: 10px solid #123783; */
            background-color: #123783;
            border-radius: 5px;
            padding-left: 5px;
            color: white;
        }

        #agora_contents_div #agora_suggestion_items_div .suggestion_item_content_div {
            margin: 1vh 0 1vh 0;
        }

        #agora_contents_div #agora_suggestion_items_div .suggestion_item_button_div {
            text-align: center;
        }


#agora_page_div {
    text-align: center;
    font-size: medium;
    margin: 2vh auto 0 auto;
}

    #agora_page_div #suggestions_curpage_span {
        font-weight: bold;
        text-decoration: underline;
    }

    #agora_page_index_div span:nth-child(2) {
        margin: 0 0 0 1vw;
    }

    #agora_page_index_div span:nth-child(3) {
        margin: 0 0 0 1vw;
    }

    #agora_page_index_div span:nth-child(4) {
        margin: 0 0 0 1vw;
    }

    #agora_page_index_div span:nth-child(5) {
        margin: 0 0 0 1vw;
    }

/* #agora_notice_button_div {
    text-align: center;
} */

@media only screen and (max-width: 767px) {

        #agora_contents_div #agora_suggestion_items_div .suggestion_item_content_div {
            margin: 1vh 0 1vh 0;
        }

    #agora_page_index_div span:nth-child(2) {
        margin: 0 0 0 4vw;
    }

    #agora_page_index_div span:nth-child(3) {
        margin: 0 0 0 4vw;
    }

    #agora_page_index_div span:nth-child(4) {
        margin: 0 0 0 4vw;
    }

    #agora_page_index_div span:nth-child(5) {
        margin: 0 0 0 4vw;
    }

}

