
.navbar_div {
    /* background-color: white; */
    background-color: var(--navbarBgColor);
}

    /*  for icons   */
    /* .navbar_div svg {
        width: 1.2em;
        height: 1.2em;
    } */

    .navbar_div .desk_nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1vh auto 0 auto;
    }

        .navbar_div .desk_nav .navbar_logo_div {
            text-align: left;
            flex-grow: 1;
        }
        
        /* .navbar_div .desk_nav .navbar_logo_div img {
            max-width: 55%;
        } */

        .navbar_div .desk_nav .navbar_menu_div {
            flex-grow: 1;
        }

            .navbar_div .desk_nav .navbar_menu_div ul {
                display: block;
                text-align: right;
            }

                    .navbar_div .desk_nav .navbar_menu_div ul li span {
                        font-size: large;
                        font-weight: bold;
                        font-family: "Noto-Sans-KR";
                    }

                    .navbar_div .mobile_hidden .desk_nav .navbar_color_scheme_div {
                        margin: 0 0 0 1vw;
                    }

    .navbar_div .mobile_nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* padding: 0 4vw; */
    }

        .navbar_div .mobile_nav .navbar_logo_div {
            text-align: left;
            display: inline-block;
            max-width: 50%;
        }

            .navbar_div .mobile_nav .navbar_logo_div img {
                /* max-width: 60%; */
                width: 80%;
            }


@media only screen and (max-width: 767px) {

.navbar_div {
    box-shadow: 0 1px 2px var(--navbarBoxShadow);
}

}