
.note_info_component_div {
    margin: 1.5vh auto 0 auto;
}

#notelist_page_index_div {
    font-family: "Noto-Sans-KR";
    font-size: medium;
    text-align: center;
    margin: 4vh 0 0.5vw 0;
}

    #notelist_page_index_div #page_index_div span {
        margin: 0 0 0 0.5vw;
    }

    #notelist_page_index_div #page_index_div #notelist_page_index_cur_page {
        text-decoration: underline;
        font-weight: 700;
    }

    #notelist_button_div #notelist_create_note_btn_div {
        text-align: center;
    }

        #notelist_button_div #notelist_create_note_btn_div button:nth-child(2){
            margin: 0 0 0 1vw;
        }

        #notelist_button_div #notelist_create_note_btn_div button:nth-child(3){
            margin: 0 0 0 1vw;
        }

    #notelist_button_div #notelist_delete_topic_btn_div {
        text-align: center;
    }

            #notelist_button_div #notelist_delete_topic_btn_div #confirm_delete_topic_message{
                margin: 0vh auto 1vh auto;
                color: crimson;
            }

        #notelist_button_div #notelist_delete_topic_btn_div button:nth-child(3){
            margin: 0 0 0 1vw;
        }

@media only screen and (max-width: 767px) {

.note_info_component_div {
    margin: 2.5vh auto 0 auto;
}

#notelist_page_index_div {
    font-family: "Noto-Sans-KR";
    font-size: medium;
    text-align: center;
    margin: 4vh 0 0 0;
}

    #notelist_page_index_div #page_index_div span {
        margin: 0 0 0 2vw;
    }

        #notelist_button_div #notelist_create_note_btn_div button:nth-child(2){
            margin: 0 0 0 3vw;
        }

        #notelist_button_div #notelist_create_note_btn_div button:nth-child(3){
            margin: 0 0 0 3vw;
        }

            #notelist_button_div #notelist_delete_topic_btn_div #confirm_delete_topic_message{
                margin: 0vh auto 2vh auto;
                color: crimson;
            }

        #notelist_button_div #notelist_delete_topic_btn_div button:nth-child(3){
            margin: 0 0 0 3vw;
        }
}

