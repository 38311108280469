
#discussion_note_list_div {
    margin: 2vh auto 2vh auto;
}

/************************************************************
/**
/**     Left(friends), Right(Me) Dialog design
/**
/***********************************************************/

/* .discussion_note_left_align {
    display: flex;
    margin: 0 0 2vh 0;
}

    .discussion_note_left_align > .False {
        flex-basis: 40vw;
        flex-grow: 1;
        position: relative; */
        /* background-color: #123783; */
        /* background-color: #28e5fe;
        padding: 1.5vw 1vw 1.5vw 1vw;
        margin: 0 0 0 2vw;
        border-radius: 1.5vw;
        cursor: pointer;
        text-align: left;
    }

    .discussion_note_left_align > .False {
        text-decoration: none;
        font-family: "Noto-Sans-KR"; */
        /* color: white; */
        /* color: black;
        font-size: medium;
        margin-left: 1vw;
        text-overflow: ellipsis;
    }

    .discussion_note_left_align > .False::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 1.5vw solid transparent; */
        /* border-right-color: #123783; */
        /* border-right-color: #28e5fe;
        border-left: 0;
        border-top: 0;
        margin-top: -0.8vw;
        margin-left: -1.4vw;
    }

        .discussion_note_left_align > .True > a {
            text-decoration: none;
            font-family: "Noto-Sans-KR";
            color: white;
            font-size: 1vw;
            margin-left: 1vw;
        }

    .discussion_note_left_align > .discussion_note_right_spacer {
        flex-basis: 24vw;
        flex-grow: 1;
    } */

/* .discussion_note_right_align {
    display: flex;
    margin: 0 0 2vh 0;
}
    
    .discussion_note_right_align > .discussion_note_left_spacer {
        flex-basis: 10vw;
        flex-grow: 1;
    }
    
    .discussion_note_right_align > .time_created_div {
        flex-basis: 20vw;
        flex-grow: 1;
        display: flex;
        justify-content: end;
        align-items: flex-end;
        text-align: right;
    }

    .discussion_note_right_align > .time_created_div > .time_created {
        color: #808080;
        margin: 0 0 0.5vw 0;
    }

    .discussion_note_right_align > .True {
        flex-basis: 60vw;
        flex-grow: 1;
        position: relative; */
        /* background-color: #28e5fe; */
        /* background-color: #123783;
        padding: 1vw 1vw 1vw 1vw;
        margin: 0 2vw 0 0;
        border-radius: 1.2vw;
        cursor: pointer;
        text-align: left;
    }

    .discussion_note_right_align > .True {
        text-decoration: none;
        font-family: "Noto-Sans-KR"; */
        /* color: black; */
        /* color: white;
        font-size: medium;
        margin-left: 1vw;
        text-overflow: ellipsis;
    }

    .discussion_note_right_align > .True::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 1.5vw solid transparent; */
        /* border-left-color: #28e5fe; */
        /* border-left-color: #123783;
        border-right: 0;
        border-top: 0;
        margin-top: -0.8vw;
        margin-right: -1.4vw;
    }

        .discussion_note_right_align > .False > a {
            text-decoration: none;
            font-family: "Noto-Sans-KR";
            color: white;
            font-size: 1vw;
            margin-left: 1vw;
        } */



/************************************************************
/**
/**     Plain list design
/**
/***********************************************************/


/* #discussion_friend_list_div {
    margin: 0 auto 2vh auto;
}

.discussion_note_subject_title_div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.discussion_note_article_title_div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.discussion_note_writer_div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.discussion_note_mine {
    cursor: pointer;
    margin: 0 0 1.5vh 0;
    border: 1px dashed #123783;
    padding: 2px;
    border-radius: 4px;
}

    .discussion_note_mine > .discussion_mine_header_row {
        background-color: #123783;
        color: white;
        padding: 0 4px 0 4px;
        border-radius: 4px;
    }

    .discussion_note_mine .discussion_note_writer_div {
        text-align: left;
    }

    .discussion_note_mine .discussion_note_timecreated_div {
        text-align: right;
    }

    .discussion_note_mine .discussion_mine_title_row {
        margin: 0.5vh 0 0 0;
    }

.discussion_note_friends {
    cursor: pointer;
    margin: 0 0 1.5vh 0; */
    /* border: 1px dashed #28e5fe; */
    /* border: 1px dashed #1cc0fb;
    padding: 2px;
    border-radius: 4px;
}

    .discussion_note_friends > .discussion_friends_header_row { */
        /* background-color: #28e5fe; */
        /* background-color: #1cc0fb;
        padding: 0 4px 0 4px;
        border-radius: 4px;
    }
    
    .discussion_note_friends .discussion_note_writer_div {
        text-align: left;
    }
    
    .discussion_note_friends .discussion_note_timecreated_div {
        text-align: right;
    }
    
    .discussion_note_friends .discussion_friends_title_row {
        margin: 0.5vh 0 0 0;
    } */


/************************************************************
/**
/**     Left(friends), Right(me) interleaving design
/**
/***********************************************************/

.discussion_note_right_align {
    margin: 0 auto 2.5vh 0;
    cursor: pointer;
    align-items: center;
}

    .discussion_note_right_align  > .True {
        border: 1px dashed #123783;
        padding: 2px;
        border-radius: 4px;
    }

        .discussion_note_right_align > .True  > .title_div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: #123783;
            color: white;
            padding: 0 4px 0 4px;
            border-radius: 4px;
            margin: 0 0 0.5vh 0;
        }

        .discussion_note_right_align > .True  > .time_div {
            text-align: right;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .discussion_note_right_align > .discussion_note_profile_pic {
            text-align: center;
        }


.discussion_note_left_align {
    margin: 0 auto 2.5vh 0;
    cursor: pointer;
    align-items: center;
}

    .discussion_note_left_align  > .False {
        border: 1px dashed #1cc0fb;
        padding: 2px;
        border-radius: 4px;
    }

        .discussion_note_left_align > .False  > .title_div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: #1cc0fb;
            color: black;
            font-weight: 600;
            padding: 0 4px 0 4px;
            border-radius: 4px;
            margin: 0 0 0.5vh 0;
        }

        .discussion_note_left_align > .False  > .time_div {
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .discussion_note_left_align > .discussion_note_profile_pic {
            text-align: center;
        }


/************************************************************
/**
/**     page index part
/**
/***********************************************************/

#discussion_page_index_div {
    font-family: "Noto-Sans-KR";
    font-size: medium;
    text-align: center;
    margin: 4vh 0 0.5vw 0;
}

    #discussion_page_index_div span {
        margin: 0 0 0 0.5vw;
    }

    #discussion_page_index_div > #discussion_page_index_cur_page {
        text-decoration: underline;
        font-weight: 700;
    }

#discussion_buttons_div {
    text-align: center;
}

    #discussion_buttons_div button {
        font-family: "Noto-Sans-KR";
    }

    #discussion_buttons_div > button:nth-child(2){
        margin: 0 0 0 1vw;
    }




@media only screen and (max-width: 767px) {

/************************************************************
/**
/**     Left(friends), Right(Me) Dialog design
/**
/***********************************************************/

/* .discussion_note_left_align {
    display: flex;
    margin: 0 0 2vh 0;
} */

    /* element */
    /* .discussion_note_left_align > .False {
        flex-basis: 60vw;
        flex-grow: 1;
        position: relative; */
        /* background-color: #123783; */
        /* background-color: #28e5fe;
        padding: 1.5vw 2vw 1.5vw 2vw;
        margin: 0 0 0 2vw;
        border-radius: 1.5vw;
        cursor: pointer;
        text-align: left;
    } */

    /* text */
    /* .discussion_note_left_align > .False {
        text-decoration: none;
        font-family: "Noto-Sans-KR"; */
        /* color: white; */
        /* color: black;
        font-size: small;
        margin-left: 1vw;
        text-overflow: ellipsis;
    }

    .discussion_note_left_align > .False::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 1.5vw solid transparent; */
        /* border-right-color: #123783; */
        /* border-right-color: #28e5fe;
        border-left: 0;
        border-top: 0;
        margin-top: -0.8vw;
        margin-left: -1.4vw;
    }

        .discussion_note_left_align > .True > a {
            text-decoration: none;
            font-family: "Noto-Sans-KR";
            color: white;
            font-size: 1vw;
            margin-left: 1vw;
        }

    .discussion_note_left_align > .discussion_note_right_spacer {
        flex-basis: 24vw;
        flex-grow: 1;
    } */

/* .discussion_note_right_align {
    display: flex;
    margin: 0 0 2vh 0;
}
    
    .discussion_note_right_align > .discussion_note_left_spacer {
        flex-basis: 24vw;
        flex-grow: 1;
    } */
   
    /* element */
    /* .discussion_note_right_align > .True {
        flex-basis: 60vw;
        flex-grow: 1;
        position: relative; */
        /* background-color: #28e5fe; */
        /* background-color: #123783;
        padding: 1.5vw 2vw 1.5vw 2vw;
        margin: 0 2vw 0 0;
        border-radius: 1.5vw;
        cursor: pointer;
        text-align: left;
    } */

    /* text */
    /* .discussion_note_right_align > .True {
        text-decoration: none;
        font-family: "Noto-Sans-KR"; */
        /* color: black; */
        /* color: white;
        font-size: small;
        margin-left: 1vw;
        text-overflow: ellipsis;
    }

    .discussion_note_right_align > .True::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 1.5vw solid transparent; */
        /* border-left-color: #28e5fe; */
        /* border-left-color: #123783;
        border-right: 0;
        border-top: 0;
        margin-top: -1vw;
        margin-right: -1.5vw;
    }

        .discussion_note_right_align > .False > a {
            text-decoration: none;
            font-family: "Noto-Sans-KR";
            color: white;
            font-size: 1vw;
            margin-left: 1vw;
        } */


/************************************************************
/**
/**     Plain list design
/**
/***********************************************************/

    /* .discussion_note_mine {
        margin: 0 0 2vh 0;
    }

    .discussion_note_friends {
        margin: 0 0 2vh 0;
    } */

/************************************************************
/**
/**     Left(friends), Right(me) interleaving design
/**
/***********************************************************/

        .discussion_note_right_align > .True  > .title_div {
            /* font-size: small; */
            font-size: 0.9em;
        }

        .discussion_note_right_align > .True  > .time_div {
            /* font-size: small; */
            font-size: 0.9em;
        }

        .discussion_note_left_align > .False  > .title_div {
            /* font-size: small; */
            font-size: 0.9em;
        }

        .discussion_note_left_align > .False  > .time_div {
            /* font-size: small; */
            font-size: 0.9em;
        }


/************************************************************
/**
/**     page index part
/**
/***********************************************************/

    #discussion_page_index_div {
        font-family: "Noto-Sans-KR";
        font-size: medium;
        text-align: center;
        margin: 4vh 0 0 0;
    }

        #discussion_page_index_div span {
            margin: 0 0 0 2vw;
        }

    #discussion_buttons_div {
        text-align: center;
    }

        #discussion_buttons_div button {
            font-family: "Noto-Sans-KR";
        }

        #discussion_buttons_div > button:nth-child(2){
            margin: 0 0 0 3vw;
        }



}
