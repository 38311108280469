
.topic_component_div {
    margin: 2vh auto 0 auto;
}

    .topic_component_div .topic_title_and_button_div {
        padding: 0 0 0.5vh 0;
        /* border-bottom: 1px solid lightgray; */
        border-bottom: 1px solid var(--topicComponentDiv_topicTitleAndButtonDivBdColor);
    }

        .topic_component_div .topic_title_and_button_div .topic_button_div {
            text-align: right;
        }

#topics_page_index_div {
    font-family: "Noto-Sans-KR";
    text-align: center;
    margin: 4vh 0 0.5vw 0;
}

    #topics_page_index_div #page_index_div span {
        font-size: large;
        margin: 0 0 0 0.7vw;
    }

    #topics_page_index_div #page_index_div #topics_page_index_cur_page {
        text-decoration: underline;
        font-weight: 700;
    }

#topics_create_topic_component {
    text-align: center;
    margin: 4vh auto 0 auto;
}

    #topics_create_topic_component #topics_create_topic_button_div {
        text-align: left;
    }
    
@media only screen and (max-width: 767px) {

.topic_component_div {
    margin: 3vh auto 0 auto;
}

#topics_page_index_div {
    font-family: "Noto-Sans-KR";
    font-size: medium;
    text-align: center;
    margin: 4vh 0 0 0;
}

    #topics_page_index_div #page_index_div span {
        margin: 0 0 0 3vw;
    }

    #topics_create_topic_component #topics_create_topic_button_div {
        text-align: center;
    }
}
