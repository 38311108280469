
#lookaround_myinfo_image_div {
    width: fit-content;
    margin: 4vh auto 5vh auto;
}

#lookaround_myinfo_image_div img {
    border-radius: 10px;
    max-width: 40vw;
}

#lookaround_myinfo_description_div {
    width: fit-content;
    font-size: medium;
    text-align: center;
    margin: 0vh auto 5vh auto;
    line-height: 200%;
}

#lookaround_myinfo_button_div {
    text-align: center;
}

    #lookaround_myinfo_button_div :nth-child(2) {
        margin: 0 0 0 1vw;
    }

@media only screen and (max-width: 767px) {

#lookaround_myinfo_image_div img {
    border-radius: 10px;
    max-width: 60vw;
}

#lookaround_myinfo_description_div {
    font-size: small;
}

    #lookaround_myinfo_button_div :nth-child(2) {
        margin: 0 0 0 4vw;
    }
}
