
#notice_div {
    /* height: 0vh; */
    overflow: hidden;
    /* transform: scaleY(0); */
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.notice_item_div {
    margin: 2vh auto 0 auto;
    font-size: medium;
}

    .notice_item_div > div:nth-child(1) {
        text-align: center;
    }

    .notice_item_div > div:nth-child(2) {
        text-align: center;
    }

@media only screen and (max-width: 767px) {

.notice_item_div {
    font-size: small;
}

}