
.app_layout {
    margin: 8vh auto 4vh auto;
    font-size: medium;
    background-color: var(--bgColor);
    color: var(--fontColor);
}

.ant-layout {
    background-color: var(--bgColor);
}

@media only screen and (max-width: 767px) {

.app_layout {
    margin: 4vh auto 8vh auto;
}

}
