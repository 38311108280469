
#my_info_content_div {
    text-align: center;
}

#my_info_pw_check_div {
    margin: 5vh auto 1vh auto;
}


    #my_info_pw_check_div #my_info_pw_check_button_div {
        margin: 2vh auto 0 auto;
    }
    
        #my_info_pw_check_div #my_info_pw_check_button_div Button:nth-child(2){
            margin: 0 0 0 1vw;
        }

#my_info_info_display_update_div #my_info_me_img {
    text-align: center;
}

#my_info_image_upload_msg_div {
    color: crimson;
}

    #my_info_info_display_update_input_div .my_info_info_display_update_input_field {
        margin: 0 auto 1vh auto;
    }

    #my_info_info_display_update_input_div #my_info_info_display_update_button_div {
        margin: 2vh auto 0 auto;
    }

        #my_info_info_display_update_input_div #my_info_info_display_update_button_div Button{
            margin: 0 1vw 0 0;
        }

#my_info_message_row {
    color: crimson;
}

#my_info_delete_account_confirm_div {
    margin: 4vh auto 0 auto;
}

#my_info_delete_account_button_div > div:nth-child(2) > button:nth-child(2){
    margin: 0 0 0 1vw;
}

/*  md size */
@media only screen and (max-width: 767px) {

    #my_info_info_display_update_input_div #my_info_info_display_update_button_div {
        margin: 4vh auto 0 auto;
    }

        #my_info_info_display_update_input_div #my_info_info_display_update_button_div Button{
            margin: 0 4vw 0 0;
        }

#my_info_delete_account_confirm_div {
    margin: 8vh auto 0 auto;
}

        #my_info_delete_account_button_div > div:nth-child(2) > button:nth-child(2){
            margin: 0 0 0 4vw;
        }

    #my_info_pw_check_div #my_info_pw_check_button_div Button:nth-child(2){
        margin: 0 0 0 3vw;
    }
}
