
#signin_top_container_div {
    margin: 2vw auto 2vw auto;
    text-align: center;
    width: 40%;
}

    #signin_page_title_div {
        margin: 2vh auto 2vh auto;
        font-size: x-large;
    }

    .signin_input_container_div {
        margin: 0 0 1vh 0;
    }

    #signin_top_container_div .signin_msg_div {
        color: red;
        margin: 0.5vh auto 0 auto;
    }

    #signin_top_container_div #signin_resetpw_div {
        margin: 2vh auto 0 auto;
    }

#signin_button_container_div {
    text-align: center;
    margin: 1vh auto 0 auto;
}

    #signin_button_container_div button {
        margin: 0 0 0 0.5vw;
    }


@media only screen and (max-width: 767px) {

    #signin_top_container_div {
        margin: 2vw auto 2vw auto;
        text-align: center;
        width: 90%;
    }

        #signin_top_container_div .signin_msg_div {
            color: red;
            margin: 0.2vh auto 0 auto;
        }

    #signin_button_container_div {
        text-align: center;
        margin: 4vh auto 0 auto;
    }

        #signin_button_container_div button {
            margin: 0 0 0 2vw;
        }

}