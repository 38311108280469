
#markdown_syntax_info_div .syntax_title {
    font-size: medium;
    font-weight: bold;
    /* color: coral; */
    color: var(--mdSyntaxInfoTitleFontColor);
}

@media only screen and (max-width: 767px) {

}