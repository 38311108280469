	/************************************
            MarkDown editor 3.0
	************************************/

/* @media screen and (min-width: 601px) { */

 	#editor_output {
		height: 100%;
		font-family: NotoSansCJKkr-Regular;
		/* font-size: 1.2vw; */
		/* overflow-y: scroll; */
		overflow-wrap: break-word;
		padding: 0 0 0 0.5vw;
		box-sizing: border-box;
		/*	border: 3px solid lime;	*/
	}

 	#editor_output blockquote {
		padding: 0 1vw;
		/*	color: #777;	*/
		/*	border-left: 4px solid #ddd;	*/
		color: gray;
		border-left: 4px solid silver;
		margin: 0;
	}

 	#editor_output div.codeblock {
		background-color: rgba(192, 192, 192, 0.3);		/*	silver 	*/
		border-radius: 3px;
		white-space: pre-wrap;
		padding: 0.5vw 0.5vw 0.5vw 1vw;
		margin: 0 0.5vw 0 0.5vw;
	}

	#editor_output div.codeblock > pre {
		margin: 0;
		white-space: pre-wrap;
	}

	#editor_output div.codeblock code {
        font-family: Consolas;
    }

	#editor_output .img {
		text-align: center;
        overflow: hidden;
		/*	box-sizing: border-box;	*/
		/*	border: 3px solid magenta;	*/
	}

	#editor_output .img > img {
		max-width: 90%;
		min-width: 30%;
	}

	#editor_output .eq {
		text-align: center;
        overflow: hidden;
		/*	box-sizing: border-box;	*/
		/*	border: 3px solid magenta;	*/
	}

		#editor_output .eq > img {
			max-width: 90%;
		}

	#editor_output hr {
		width: 80%;
		border: none;
		border-top: 2px solid silver;
		margin: 20px auto 20px auto;
	}

	#editor_output a {
		text-decoration: underline;	
	}

	#editor_output h1 {
		margin-top: 0.5em;
	}
 
/* } */

/*	600px width: phone	*/

@media screen and (max-width: 600px) {

 	#editor_output {
		height: 100%;
		font-family: NotoSansCJKkr-Regular;
		/* font-size: 4vw; */
		overflow-y: scroll;
		overflow-wrap: break-word;
		padding: 0 0 0 1vw;
		box-sizing: border-box;
		/*	border: 3px solid lime;	*/
	}

	#editor_output blockquote {
		padding: 0 1vw;
		/*	color: #777;	*/
		/*	border-left: 4px solid #ddd;	*/
		color: gray;
		border-left: 4px solid silver;
		margin: 0;
	}

	#editor_output div.codeblock {
		background-color: rgba(192, 192, 192, 0.3);		/*	silver 	*/
		border-radius: 3px;
		white-space: pre-wrap;
		padding: 0.5vw 0.5vw 0.5vw 2vw;
		margin: 0 1vw 0 1vw;
	}

	#editor_output div.codeblock > pre {
		margin: 0;
		white-space: pre-wrap;
	}

	#editor_output div.codeblock code {
        font-family: Consolas;
    }

	#editor_output .img {
		text-align: center;
        overflow: hidden;
		/*	box-sizing: border-box;	*/
		/*	border: 3px solid magenta;	*/
	}

	#editor_output .img > img {
		max-width: 100%;
		min-width: 30%;
	}

	#editor_output .eq {
		text-align: center;
        overflow: hidden;
		/*	box-sizing: border-box;	*/
		/*	border: 3px solid magenta;	*/
	}

		#editor_output .eq > img {
			max-width: 90%;
		}

	#editor_output hr {
		width: 80%;
		border: none;
		border-top: 2px solid gray;
	}

	#editor_output a {
		text-decoration: underline;	
	}

}
